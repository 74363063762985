<template v-if="initialized">
    <div class="d-flex flex-column h-100 w-100">
        <div class="d-flex flex-column justify-content-center flex-1 bg-black p-2" v-if="!isMediaDevicesSupported">
            <p class="text-center text-white">{{ $t('This device does not support camera functionality') }}</p>
        </div>

        <div class="d-flex flex-column justify-content-center flex-1 bg-black p-2" v-else-if="videoDevices.length === 0">
            <p class="text-center text-white">{{ $t('No camera detected. Makes sure a camera is connected and try again.') }}</p>
        </div>

        <div class="d-flex flex-column justify-content-center flex-1 bg-black p-2" v-else-if="hasMediaStreamError">
            <p class="text-center text-white">{{ $t('An error occurred while starting the camera. If the problem persists, please contact support.') }}</p>
        </div>

        <div class="d-flex flex-column flex-1 bg-black" v-else>
            <div class="d-flex justify-content-center align-items-center flex-1 position-relative">
                <video class="position-absolute h-100 w-100" ref="video" autoplay></video>
                <canvas class="position-absolute m-auto" ref="canvas"></canvas>
            </div>

            <div class="d-flex justify-content-between align-items-center text-nowrap p-3" v-if="imageTaken">
                <div class="d-flex justify-content-center align-items-center flex-1">
                    <button class="btn btn-link fs-4 text-white" @click="retakeImage()">
                        {{ $t('Retake') }}
                    </button>
                </div>

                <div class="d-flex justify-content-center align-items-center flex-1">
                    <button class="btn btn-link fs-4 text-white" @click="useImage()">
                        {{ $t('Use') }}
                    </button>
                </div>
            </div>
            
            <div class="d-flex justify-content-between align-items-center p-3" v-else>
                <div class="d-flex justify-content-start align-items-center flex-1">
                    <button class="camera-torch-button btn btn-link fs-1" v-if="torchSupported">
                        <i class="bi" :class="{
                            'bi-lightning': !torchEnabled,
                            'bi-lightning-fill': torchEnabled
                        }" @click="toggleBlitz()" style="align-self: center;"></i>
                    </button>
                </div>

                <div class="d-flex justify-content-center align-items-center flex-1">
                    <div class="camera-button" @click="takePicture()">
                        <div class="camera-button-circle"></div>
                        <div class="camera-button-ring"></div>
                    </div>
                </div>

                <div class="d-flex justify-content-end align-items-center flex-1">
                    <button class="camera-cycle-button btn btn-link fs-1" @click="cycleVideoInputs()" v-if="supportedFacingModes.size > 1">
                        <i class="bi bi-arrow-repeat" style="align-self: center;"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, defineProps, onBeforeUnmount, computed } from 'vue';

    import type DataObject from 'o365.modules.DataObject.ts';

    /* -------------------- */
    /* ---- Interfaces ---- */
    /* -------------------- */
    export interface IProps {
        dataObject?: DataObject 
    }

    export interface IDefaultProps {}

    export interface ICameraSettings {
        blitzEnabled: boolean;
    }

    export interface VideoDeviceInfo extends MediaDeviceInfo {
        getCapabilities: () => IVideoDeviceCapabilities;
    }

    export interface IVideoDeviceCapabilities extends MediaTrackCapabilities {
        torch?: boolean;
    }

    export interface IVideoDeviceConstraints extends MediaTrackConstraints {
        torch?: boolean;
    }

    export interface IVideoDeviceSettings extends MediaTrackSettings {
        torch?: boolean;
    }

    /* --------------- */
    /* ---- Types ---- */
    /* --------------- */
    export type FacingMode = 'environment' | 'user' | 'left' | 'right';

    /* ------------------- */
    /* ---- Constants ---- */
    /* ------------------- */
    const facingModeOrder = ['environment', 'user', 'left', 'right'] as const;

    /* ----------------------- */
    /* ---- Template Refs ---- */
    /* ----------------------- */
    const video = ref<HTMLVideoElement | null>(null);
    const canvas = ref<HTMLCanvasElement | null>(null);

    /* ---------------------- */
    /* ---- Status Flags ---- */
    /* ---------------------- */
    const initialized = ref<boolean>(false);
    const loadingMediaStream = ref<boolean>(false);
    const hasMediaStreamError = ref<boolean>(false);
    const imageTaken = ref<boolean>(false);

    /* -------------------------------- */
    /* ---- Media Stream Variables ---- */
    /* -------------------------------- */
    const mediaStream = ref<MediaStream | null>(null);
    const mediaDevices = ref<Array<MediaDeviceInfo>>(new Array());
    const videoTrackCapabilities = ref<IVideoDeviceCapabilities | null>(null);
    const videoTrackConstraints = ref<IVideoDeviceConstraints | null>(null);
    const videoTrackSettings = ref<IVideoDeviceSettings | null>(null);

    /* --------------- */
    /* ---- Props ---- */
    /* --------------- */
    const props = withDefaults<IProps, keyof IProps, IDefaultProps>(defineProps<IProps>(), {});

    /* --------------- */
    /* ---- Emits ---- */
    /* --------------- */
    const emit = defineEmits<{
        (e: 'useImage', file: File): void
    }>();

    /* ---------------------------- */
    /* ---- Computed Variables ---- */
    /* ---------------------------- */
    const isMediaDevicesSupported = computed(() => {
        return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    });

    const videoTracks = computed(() => {
        return mediaStream.value?.getVideoTracks() ?? new Array<MediaStreamTrack>();
    });

    const enabledVideoTrack = computed(() => {
        return videoTracks.value.find((videoTrack) => videoTrack.enabled) ?? null;
    });

    const videoDevices = computed(() => {
        return mediaDevices.value.filter((mediaDevice) => mediaDevice.kind === 'videoinput') as Array<VideoDeviceInfo>;
    });

    const supportedFacingModes = computed(() => {
        const facingModes = videoDevices.value
            .map((videoDevice) => videoDevice.getCapabilities().facingMode)
            .flat()
            .filter((facingMode) => facingMode !== undefined) as Array<string>;

        return new Set<string>(facingModes);
    });

    const torchSupported = computed(() => {
        return videoTrackCapabilities.value?.torch ?? false;
    });

    const torchEnabled = computed(() => {
        return videoTrackSettings.value?.torch ?? false;
    });

    /* ------------------------- */
    /* ---- Lifecycle Hooks ---- */
    /* ------------------------- */
    onMounted(async () => {
        await initializeMediaStreamAsync('environment');
    });

    onBeforeUnmount(() => {
        stopAllLoadedMediaTracks();
    });

    /* ------------------- */
    /* ---- Functions ---- */
    /* ------------------- */
    const initializeAsync = async () => {
        initialized.value = false;

        const devices = await navigator.mediaDevices.enumerateDevices();

        mediaDevices.value = devices;

        initialized.value = true;
    }

    const stopAllLoadedMediaTracks = () => {
        mediaStream.value?.getTracks().forEach((track) => {
            track.stop();
        });
    }

    const initializeMediaStreamAsync = async (facingMode: FacingMode) => {
        try {
            loadingMediaStream.value = true;

            stopAllLoadedMediaTracks();

            mediaStream.value = await window.navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: facingMode
                },
                audio: false
            });

            videoTrackCapabilities.value = enabledVideoTrack.value?.getCapabilities() ?? null;
            videoTrackConstraints.value = enabledVideoTrack.value?.getConstraints() ?? null;
            videoTrackSettings.value = enabledVideoTrack.value?.getSettings() ?? null;

            video.value!.srcObject = mediaStream.value;

            await availableDevicesAsync();

            hasMediaStreamError.value = false;
        } catch (reason) {
            console.error(reason);

            hasMediaStreamError.value = true;            
        } finally {
            loadingMediaStream.value = false;
        }
    }

    const availableDevicesAsync = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();

        mediaDevices.value = devices;
    }

    const toggleBlitz = async () => {
        await updateVideoTrackConstraints({
            advanced: [<MediaTrackConstraintSet>{
                torch: !(videoTrackSettings.value?.torch ?? false)
            }]
        });
    }

    const cycleVideoInputs = async () => {
        const supportedFacingModeOrder = facingModeOrder.filter(facingMode => supportedFacingModes.value.has(facingMode));

        const currentFacingMode = videoTrackSettings.value?.facingMode;

        const currentFacingModeIndex = currentFacingMode 
            ? supportedFacingModeOrder.indexOf(currentFacingMode as FacingMode) 
            : supportedFacingModeOrder.indexOf('environment');

        const safeCurrentFacingModeIndex = currentFacingModeIndex >= 0 ? currentFacingModeIndex : 0;

        const nextFacingModeIndex = (safeCurrentFacingModeIndex + 1) % supportedFacingModeOrder.length;

        const nextFacingMode = supportedFacingModeOrder[nextFacingModeIndex];

        await initializeMediaStreamAsync(nextFacingMode);
    }

    const takePicture = () => {
        if (canvas.value === null || video.value === null) {
            return;
        }

        const context = canvas.value.getContext('2d');

        if (context === null) {
            return;
        }

        const containerWidth = canvas.value.parentElement!.clientWidth;
        const containerHeight = canvas.value.parentElement!.clientHeight;
        const videoAspectRatio = video.value.videoWidth / video.value.videoHeight;
        
        let canvasCSSWidth: number, canvasCSSHeight: number;
        
        if (containerWidth > containerHeight * videoAspectRatio) {
            canvasCSSWidth = containerHeight * videoAspectRatio;
            canvasCSSHeight = containerHeight;
        } else {
            canvasCSSWidth = containerWidth;
            canvasCSSHeight = containerWidth / videoAspectRatio;
        }
        
        canvas.value.style.width = `${canvasCSSWidth}px`;
        canvas.value.style.height = `${canvasCSSHeight}px`;

        canvas.value.width = video.value.videoWidth;
        canvas.value.height = video.value.videoHeight;

        context.clearRect(0, 0, canvas.value.width, canvas.value.height);
        context.drawImage(video.value, 0, 0, video.value.videoWidth, video.value.videoHeight);

        imageTaken.value = true;
        
        stopAllLoadedMediaTracks();
    };

    const useImage = async () => {
        if (canvas.value === null) {
            throw new Error('Canvas is null');
        }

        const file = await new Promise<File>((resolve, reject) => {
            if (canvas.value === null) {
                reject(new Error('Canvas is null'));

                return;
            }

            canvas.value.toBlob((blob) => {
                try {
                    if (blob === null) {
                        reject(new Error('Failed to create blob from canvas'));

                        return;
                    }

                    const fileNameWithoutExtension = new Date().toISOString()
                        .replace(/T/, '_')
                        .replace(/:\d{2}.\d{3}Z/, '')
                        .replace(/:/g, '')
                        .replace(/-/g, '');

                    const file = new File([blob], `${fileNameWithoutExtension}.png`, { type: 'image/png' });

                    resolve(file);
                } catch (reason) {
                    reject(reason);
                }
            }, 'image/png');
        });

        emit('useImage', file);
    }

    const retakeImage = () => {
        if (canvas.value === null || video.value === null ) {
            return;
        }

        const context = canvas.value.getContext('2d');

        if (context === null) {
            return;
        }
        
        context.clearRect(0, 0, canvas.value.width, canvas.value.height);

        imageTaken.value = false;

        initializeMediaStreamAsync('environment');
    }

    const updateVideoTrackConstraints = async (newConstraints: IVideoDeviceConstraints) => {
        const updatedVideoTrackConstraints = Object.assign({}, videoTrackConstraints.value, newConstraints);
        
        await enabledVideoTrack.value?.applyConstraints(updatedVideoTrackConstraints);

        retrieveVideoTrackConstraintsAndSettings();
    }

    const retrieveVideoTrackConstraintsAndSettings = () => {
        videoTrackConstraints.value = enabledVideoTrack.value?.getConstraints() as IVideoDeviceConstraints | null;
        videoTrackSettings.value = enabledVideoTrack.value?.getSettings() as IVideoDeviceSettings | null;
    }

    initializeAsync();
</script>

<style scoped>
    .camera-button {
        position: relative;
        width: 75px;
        height: 75px;
        cursor: pointer;
    }

    .camera-button-ring {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid grey;
        border-radius: 50%;
    }

    .camera-button-circle {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        background-color: grey;
        border-radius: 50%;
        transition: transform 0.2s ease-in-out;
    }

    .camera-button:active .camera-button-circle {
        transform: scale(1.2);
    }

    .camera-cycle-button, .camera-torch-button {
        width: 60px;
        height: 60px;
        background-color: rgba(169, 169, 169, 0.3);
        border-radius: 50%;
    }
</style>